import type {VariantProps} from 'class-variance-authority'
import {cva} from 'class-variance-authority'
import {forwardRef} from 'react'

type Size = VariantProps<typeof buttonClasses>['size']
type Color = Exclude<VariantProps<typeof buttonClasses>['variant'], 'disabled'>
export type ButtonProps = React.ComponentProps<'button'> & {
  ref?: React.ForwardedRef<HTMLButtonElement>
  size?: Size
  variant: Color
}

export const buttonClasses = cva(
  'inline-flex items-center whitespace-nowrap border shadow-xs transition duration-300 hover:duration-150 focus:outline-hidden',
  {
    variants: {
      variant: {
        purple:
          'border-radix-violet7 bg-radix-violet3 text-radix-violet11 hover:border-radix-violet8 focus:ring-radix-violet8',
        gray: 'border-radix-mauve7 bg-radix-mauve3 text-radix-mauve11 hover:border-radix-mauve8 focus:ring-radix-mauve8',
        red: 'border-radix-red7 bg-radix-red3 text-radix-red11 hover:border-radix-red8 focus:ring-radix-red8',
        disabled: 'border-radix-mauve7 bg-radix-mauve3 text-radix-mauve11',
      },
      size: {
        large: 'rounded-md px-4 py-2 font-medium focus:ring-2 focus:ring-offset-2 text-sm',
        small: 'rounded-sm px-2 py-1 text-xs',
      },
    },
  },
)

function Button(
  {children, variant, size = 'large', className, disabled, ...rest}: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const color = disabled ? 'disabled' : variant
  return (
    <button className={buttonClasses({variant: color, size, className})} {...rest} disabled={disabled} ref={ref}>
      {children}
    </button>
  )
}

const ButtonWithRef: (props: ButtonProps) => React.ReactNode = forwardRef(Button)

export {ButtonWithRef as Button}
